import axios from 'axios'
import { getDefaultDateRange } from 'utils/functions/helpers'
import { apiUrl, mygeotabUrl } from '../apiUrl'

const url = apiUrl()

function setToMidnight(date) {
	date.setHours(0, 0, 0, 0)
	return date
}

function setToNextDayMidnight(date) {
	date.setHours(0, 0, 0, 0)
	date.setDate(date.getDate() + 1)
	return date
}

const mapApi = {
	login(user) {
		return axios.post(`${url}/integrations/20/auth`, user, {
			headers: {
				Accept: 'application/json',
			},
		})
	},

	getAllDevices(userInfo) {
		return axios.post(`${url}/integrations/20/devices/query`, {
			...userInfo,
			returnUser: false,
			returnDevice: true,
			returnDeviceStatus: false,
			returnVin: false,
			returnAddress: false,
			returnZone: false,
			returnOdometer: false,
			returnEngineHours: false,
			returnFuelLevel: false,
		})
	},

	getVehicleLocationFromMygeotab({ userInfo, vehicleId, fromDate, toDate }) {
		const { fromDate: defaultFromDate, toDate: defaultToDate } = getDefaultDateRange()

		const newFromDate = fromDate ? setToMidnight(new Date(fromDate)) : defaultFromDate
		const newToDate = toDate ? setToNextDayMidnight(new Date(toDate)) : defaultToDate

		return axios.post(mygeotabUrl, {
			method: 'Get',
			params: {
				typeName: 'LogRecord',
				search: {
					deviceSearch: {
						id: vehicleId,
					},
					fromDate: newFromDate,
					toDate: newToDate,
				},
				sort: {
					sortby: 'datetime',
					sortdirection: 'asc',
				},
				credentials: userInfo.credentials,
			},
		})
	},

	getStopLocationFromMygeotab({ userInfo, vehicleId, fromDate, toDate }) {
		const { fromDate: defaultFromDate, toDate: defaultToDate } = getDefaultDateRange()

		const newFromDate = fromDate ? setToMidnight(new Date(fromDate)) : defaultFromDate
		const newToDate = toDate ? setToNextDayMidnight(new Date(toDate)) : defaultToDate

		return axios.post(mygeotabUrl, {
			method: 'Get',
			params: {
				typeName: 'Trip',
				search: {
					deviceSearch: {
						id: vehicleId,
					},
					fromDate: newFromDate,
					toDate: newToDate,
					includeoverlappedtrips: true,
				},
				sort: {
					sortby: 'datetime',
					sortdirection: 'asc',
				},
				credentials: userInfo.credentials,
			},
		})
	},

	getAdditionalLocationFromMygeotab({ userInfo, vehicleId, fromDate, fromVersion }) {
		const { fromDate: defaultFromDate } = getDefaultDateRange()

		const newFromDate = fromDate ? setToMidnight(new Date(fromDate)) : defaultFromDate

		return axios.post(mygeotabUrl, {
			method: 'GetFeed',
			params: {
				typeName: 'LogRecord',
				search: {
					deviceSearch: {
						id: vehicleId,
					},
					// If fromVersion have value, fromDate will be ignored
					fromDate: fromVersion ? undefined : newFromDate,
				},
				fromVersion: fromVersion ?? undefined,
				credentials: userInfo.credentials,
			},
		})
	},

	getCurrentVehicleFromMygeotab({ userInfo, vehicleId }) {
		return axios.post(mygeotabUrl, {
			method: 'Get',
			params: {
				typeName: 'DeviceStatusInfo',
				search: {
					deviceSearch: {
						id: vehicleId,
					},
					fromDate: new Date().toISOString(),
				},
				credentials: userInfo.credentials,
			},
		})
	},

	getCurrentUserFromMygeotab({ userInfo, vehicleId }) {
		const credentials = userInfo.credentials

		return axios.post(mygeotabUrl, {
			method: 'Get',
			params: {
				typeName: 'User',
				search: {
					deviceSearch: {
						name: credentials?.userName,
					},
					fromDate: new Date().toISOString(),
				},
				credentials: credentials,
			},
		})
	},
}

export default mapApi
