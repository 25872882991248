import { apiUrl } from '../../../apis/apiUrl'
import axios from 'axios'
import { computeOwnerNameByType } from '../../data/dataHelpers'

const url = apiUrl()

export const getCoreData = async (token, t) => {
	const [
		accountResponse,
		themeResponse,
		userCurrentResponse,
		devicesResponse,
		ownersResponse,
		translationsResponse,
		linksResponse,
		settingsResponse,
	] = await Promise.all([
		getAccount(token),
		getTheme(token),
		getUserCurrent(token),
		getDevices(token),
		getOwners(token),
		getTranslations(token),
		getLinks(),
		getSettings(token),
	])

	const account = (await accountResponse.data) || {}
	const theme = (await themeResponse.data) || {}
	const userCurrent = (await userCurrentResponse.data) || {}
	const devices = (await devicesResponse.data) || {}
	const owners = (await ownersResponse.data) || []
	const translations = (await translationsResponse.data) || {}
	const links = (await linksResponse.data) || []
	const settings = (await settingsResponse.data) || {}

	return {
		account,
		theme,
		userCurrent,
		devices,
		owners:
			owners.length &&
			owners.map((owner) => ({
				...owner,
				name: owner.name ? owner.name : computeOwnerNameByType(owner.type, t),
			})),
		translations,
		links,
		settings,
	}
}

const getAccount = (token) =>
	axios(`${url}/accounts/current`, {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
	})

const getTheme = (token) =>
	axios(`${url}/themes/portal`, {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
	})

const getUserCurrent = (token) =>
	axios(`${url}/users/current`, {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
	})

export const getDevices = (token) =>
	axios(`${url}/devices`, {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
	})

const getOwners = (token) =>
	axios(`${url}/owners`, {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
	})

const getTranslations = (token) =>
	axios(`${url}/translations/current`, {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
	})

const getLinks = () =>
	axios(`${url}/links`, {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
	})

export const updateUserCurrent = async (userCurrent, token) => {
	const config = {
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${token}`,
		},
	}

	return await axios.put(`${url}/users/current`, userCurrent, config)
}

const getSettings = (token) =>
	axios(`${url}/settings`, {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
	})
