import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@mui/styles'
import AppFullScreen from './components/AppFullScreen'
import { isAuthenticated } from './components/auth/AuthServices'
import SignIn from './components/user/SignIn'
import LoadingSpinner from './custom-components/LoadingSpinner'
import { ENV_ACTIONS } from './reducers/environmentReducer'
import queryString from 'query-string'
import _ from 'lodash'

const useStyles = makeStyles(() => ({
	layoutFullscreen: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'stretch',
		position: 'relative',
		height: '100vh',
	},
}))

const App = () => {
	const classes = useStyles()

	const environment = useSelector((state) => state.environment)
	const dispatch = useDispatch()
	// const userSession = isAuthenticated();
	const [userSession, setUserSession] = useState(isAuthenticated())

	const showLoading = () => environment.isLoading && <LoadingSpinner />

	const initializedEnvironment = () => {
		dispatch({
			type: ENV_ACTIONS.INITIALIZED_ENVIRONMENT,
		})
	}

	const checkSession = () =>
		userSession &&
		dispatch({
			type: ENV_ACTIONS.GET_USER,
			payload: {
				user: userSession,
			},
		})

	const renderApp = () =>
		userSession ? (
			<AppFullScreen
				isAuthenticated={environment.isAuthenticated}
				apiToken={environment.apiToken}
				timeoutMinutes={environment?.settings?.portal?.security?.timeoutMinutes}
				lockEnabled={environment?.settings?.portal?.security?.lockEnabled}
			/>
		) : (
			<SignIn />
		)

	useEffect(() => {
		initializedEnvironment()
		checkSession()
	}, [])

	useEffect(() => {
		setUserSession(environment.isAuthenticated)
	}, [environment.isAuthenticated])

	useEffect(() => {
		// Set token when open Doforms from Geotab
		const searchParams = new URL(document.location).searchParams
		const data = queryString.parse(searchParams.toString())

		if (!_.isEmpty(data) && data.isFromGeotab) {
			sessionStorage.setItem('jwt', JSON.stringify(data))
			const url = new URL(window.location.href)
			url.search = ''
			const newUrl = url.toString()
			window.location.href = newUrl
		}
	}, [])

	return (
		<div className={classes.layoutFullscreen}>
			{showLoading()}
			{renderApp()}
		</div>
	)
}

const mapStateToProps = (state) => {
	return {
		environment: state.environment,
		formsModule: state.formsModule,
		lookupsModule: state.lookupsModule,
	}
}

export default connect(mapStateToProps)(App)
