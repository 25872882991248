import ConstructionIcon from '@mui/icons-material/Construction'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit'
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark'
import SettingsIcon from '@mui/icons-material/Settings'
import VideocamIcon from '@mui/icons-material/Videocam'
import { Box, Stack, Tooltip } from '@mui/material'
import Fade from '@mui/material/Fade'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { makeStyles } from '@mui/styles'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useParams } from 'react-router-dom'
import { useUrlQueryParams } from 'utils/hooks/useUrlQueryParams'
import { CoreContext } from '../../../custom-components/context/CoreContext'
import { ENV_ACTIONS } from '../../../reducers/environmentReducer'
import '../../../styles.css'
import { checkAdminRole, isJson } from '../../../utils/functions/helpers'
import useMenuQuery from '../../pages/dis/hooks/useMenuQuery'
import VisibilityIcon from '@mui/icons-material/Visibility'

const RIGHT_SIDE_MENU_LIST = [
	{
		name: 'question',
		icon: QuestionMarkIcon,
	},
	{
		name: 'video',
		icon: VideocamIcon,
	},
	{
		name: 'headset',
		icon: HeadsetMicIcon,
	},
	{
		name: 'contruction',
		icon: ConstructionIcon,
	},
	{
		name: 'setting',
		icon: SettingsIcon,
	},
]

const useStyles = makeStyles(() => ({
	menuAppbar: (props) => ({
		background: props.backgroundColor,
		height: '30px',
		border: 'none',
		display: 'flex',
		paddingLeft: 0,
		marginBottom: 0,
		listStyle: 'none',
	}),
	toolbar: {
		minHeight: 'fit-content',
	},
	menuNavLink: (props) => ({
		display: 'flex',
		padding: '0.2rem 1rem',
		color: props.color,
		'&:hover': {
			backgroundColor: props.active.backgroundColor,
			color: props.active.color,
			textDecoration: 'none',
			cursor: 'pointer',
		},
	}),
	menuNavIconLink: (props) => ({
		display: 'flex',
		padding: '0.2rem 0.5rem',
		color: props.isActive ? props.active.color : props.color,
		backgroundColor: props.isActive ? props.active.backgroundColor : undefined,

		'&:hover, &.isActive': {
			cursor: 'pointer',
			backgroundColor: props.active.backgroundColor,
			color: props.active.color,
			textDecoration: 'none',
		},
	}),

	selected: (props) => ({
		backgroundColor: props.active.backgroundColor,
		color: props.active.color,
	}),

	icon: (props) => ({
		fontSize: '1.5em',
	}),
}))

const subMenuLinks = {
	mnMobileUnits: 'https://support.doforms.com/hc/en-us/articles/1500007337962',
	mnForms: 'https://support.doforms.com/hc/en-us/articles/1500007337942',
	mnScheduler: 'https://support.doforms.com/hc/en-us/articles/360046564933',
	mnLookups: 'https://support.doforms.com/hc/en-us/articles/1500005519222',
	mnViews: 'https://support.doforms.com/hc/en-us/articles/1500005483522',
	mnMyReports: 'https://support.doforms.com/hc/en-us/articles/1500005519862',
	mnVideos: 'https://www.doforms.com/video-library/',
	mnSupport:
		'https://mydoforms.appspot.com/previewSubmit?id=ag9zfm15ZG9mb3Jtcy1ocmRyGAsSC1Byb2plY3RGb3JtGICAsNeX65sJDA&embedhtml=null&embedlink=null&email=null&hideToolbar=null&viewmode=null&hideLogo=null&mobileUserKey=&recordKey=&view=null&ornt=null&size=null&formlink=null&canSave=null&canSchedule=null&hideComplete=&hideSubmit=',
	mnMarketplace: 'https://www.doforms.com/marketplace/',
}

function findMenuItemWithDashboardKey(menuList, dashboardKey) {
	return menuList.find((menu) => menu.items.some((item) => item.dashboard.key === dashboardKey))
}

const DisNavTabs = ({ menuItemList, isDynamicMenu, isShowMenuItems, setIsShowMenuItems }) => {
	const urlQueryParam = useUrlQueryParams()
	const fullscreenQuery = urlQueryParam.get('fullscreen')
	const { id } = useParams()

	const { coreValues } = useContext(CoreContext)
	const { environment } = useSelector((state) => state)

	const { translations, theme } = coreValues
	const menuBarTheme = theme.menuBar
	const classes = useStyles(menuBarTheme)
	const [t] = useTranslation('common')

	const [anchorEl, setAnchorEl] = useState(null)
	const [open, setOpen] = useState(false)
	const [iconMenuSelected, setIconMenuSelected] = useState(null)
	const [fullscreenMode, setFullscreenMode] = useState(fullscreenQuery === '1' ? true : false)
	const [fullscreenHover, setFullscreenHover] = useState(false)
	const [fullscreenExitHover, setFullscreenExitHover] = useState(false)
	const [rightSideMenu, setRightSideMenu] = useState([])

	const dispatch = useDispatch()

	const isAdmin = checkAdminRole(environment?.userCurrent)

	const { menuList } = useMenuQuery()

	useEffect(() => {
		if (!id || menuList?.length === 0 || !isDynamicMenu) {
			setRightSideMenu([])
			return
		}

		const detailData = findMenuItemWithDashboardKey(menuList, id)

		if (!detailData?.length === 0) return

		if (detailData?.settings) {
			setRightSideMenu(isJson(detailData?.settings) ? JSON.parse(detailData?.settings) : [])
		} else {
			setRightSideMenu([])
		}
	}, [id, menuList, isDynamicMenu])

	const handleToggleFullscreen = (e, toggle) => {
		setFullscreenMode(toggle)
		dispatch({
			type: ENV_ACTIONS.TOGGLE_FULLSCREEN_MODE,
			payload: toggle,
		})

		if (toggle) {
			setTimeout(() => {
				setFullscreenHover(false)
			}, 500)
		} else {
			setTimeout(() => {
				setFullscreenExitHover(false)
			}, 500)
		}
	}

	const handleMenuIconClick = (e, target) => {
		setAnchorEl(e.currentTarget)
		setIconMenuSelected(target)

		switch (target) {
			case 'question':
				break
			case 'video':
				break
			case 'headset':
				break
			case 'contruction':
				break
			case 'setting':
				break
			default:
				return
		}
		setOpen(true)
	}

	const handleClose = () => {
		setAnchorEl(null)
		setOpen(false)
	}

	const launchBuildForms = (event) => {
		event.preventDefault()
		event.stopPropagation()
		var w = window.open(environment.renderHost + '?target=buildforms&token=' + environment.apiToken)
		w.document.title = 'doForms'
		handleClose()
	}

	const launchBuildReports = (event) => {
		event.preventDefault()
		event.stopPropagation()
		var w = window.open(
			environment.renderHost + '?target=buildreports&token=' + environment.apiToken
		)
		w.document.title = 'doForms'
		handleClose()
	}

	const launchMobileUsers = (event) => {
		event.preventDefault()
		event.stopPropagation()
		var w = window.open(environment.renderHost + '?target=devices&token=' + environment.apiToken)
		w.document.title = 'doForms'
		handleClose()
	}

	const launchWebUsers = (event) => {
		event.preventDefault()
		event.stopPropagation()
		var w = window.open(environment.renderHost + '?target=users&token=' + environment.apiToken)
		w.document.title = 'doForms'
		handleClose()
	}

	const launchConfigSystem = (event) => {
		event.preventDefault()
		event.stopPropagation()
		var w = window.open(environment.renderHost + '?target=manage&token=' + environment.apiToken)
		w.document.title = 'doForms'
		handleClose()
	}

	const launchMyAccount = (event) => {
		event.preventDefault()
		event.stopPropagation()
		var w = window.open(environment.renderHost + '?target=account&token=' + environment.apiToken)
		w.document.title = 'doForms'
		handleClose()
	}

	const launchChat = (event) => {
		event.preventDefault()
		event.stopPropagation()
		window.olark('api.box.expand')
		handleClose()
	}

	const launchDispatchScheduler = (event) => {
		event.preventDefault()
		event.stopPropagation()
		var w = window.open(environment.renderHost + '?target=scheduler&token=' + environment.apiToken)
		w.document.title = 'doForms'
	}

	const linkTo = (tab) => {
		return isDynamicMenu
			? `/dis/${tab?.dashboard?.key}`
			: `/dis/default/${tab?.page?.path?.slice(1)}`
	}

	return (
		<Box sx={{ '& li': { listStyle: 'none' } }}>
			<Stack className={classes.menuAppbar} direction="row" justifyContent="space-between">
				<Box component="ul" sx={{ display: 'flex', p: 0, m: 0 }}>
					{menuItemList?.length > 0 &&
						menuItemList.map((tab, index) => {
							return (
								<li key={index} className="nav-item">
									{tab?.page?.path?.slice(1) === 'scheduler' && (
										<p className={classes.menuNavLink} onClick={(e) => launchDispatchScheduler(e)}>
											{tab.name}
										</p>
									)}
									{tab?.page?.path?.slice(1) !== 'scheduler' && (
										<NavLink
											className={classes.menuNavLink}
											activeClassName={classes.selected}
											to={linkTo(tab)}
										>
											{tab.name}
										</NavLink>
									)}
									{tab?.items?.length > 0 && (
										<ul
											className="menu-dropdown-list"
											style={{
												backgroundColor: menuBarTheme.backgroundColor,
											}}
										>
											{tab.items.map((child, index) => {
												return (
													<li key={index} className="menu-dropdown-item">
														<NavLink
															className={classes.menuNavLink}
															activeClassName={classes.selected}
															to={linkTo(child)}
														>
															{child.name}
														</NavLink>
													</li>
												)
											})}
										</ul>
									)}
								</li>
							)
						})}
				</Box>

				<Stack direction="row">
					<Box component="ul" sx={{ display: 'flex' }}>
						{isAdmin && (
							<>
								<li className="nav-item">
									<Tooltip
										key="show-menu-items"
										title="Show menu items"
										arrow
										placement="bottom-start"
									>
										<NavLink
											to="#"
											onClick={() => setIsShowMenuItems((prev) => !prev)}
											className={`${classes.menuNavIconLink} ${isShowMenuItems ? 'isActive' : ''}`}
										>
											<VisibilityIcon />
										</NavLink>
									</Tooltip>
								</li>
								<li className="nav-item">
									<Tooltip
										key="menu-manager"
										title={t('common:dis.menuManager')}
										arrow
										placement="bottom-start"
									>
										<NavLink className={classes.menuNavIconLink} to="/dis/menu-manager">
											<svg
												stroke="currentColor"
												fill="none"
												strokeWidth="2"
												viewBox="0 0 24 24"
												strokeLinecap="round"
												strokeLinejoin="round"
												height="1.4em"
												width="1.4em"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
												<path d="M4 12v-6a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-6"></path>
												<path d="M4 10h16"></path>
												<path d="M10 4v9"></path>
												<path d="M5.281 18.5m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
												<path d="M5.281 15v1.5"></path>
												<path d="M5.281 20.5v1.5"></path>
												<path d="M8.312 16.75l-1.299 .75"></path>
												<path d="M3.55 19.5l-1.3 .75"></path>
												<path d="M2.25 16.75l1.3 .75"></path>
												<path d="M7.013 19.5l1.3 .75"></path>
											</svg>
										</NavLink>
									</Tooltip>
								</li>
							</>
						)}
						<li className="nav-item">
							{fullscreenMode ? (
								<Tooltip
									key="expand"
									title={`${t('tooltip.expand')}`}
									arrow
									placement="bottom-start"
									disableInteractive
									disableHoverListener
									open={!fullscreenHover && fullscreenExitHover}
								>
									<NavLink
										className={classes.menuNavIconLink}
										to="#"
										onClick={(e) => handleToggleFullscreen(e, !fullscreenMode)}
										onMouseEnter={() => setFullscreenExitHover(true)}
										onMouseLeave={() => setFullscreenExitHover(false)}
									>
										<FullscreenExitIcon className={classes.icon} />
									</NavLink>
								</Tooltip>
							) : (
								<Tooltip
									key="fullscreen"
									title={`${t('tooltip.fullscreen')}`}
									arrow
									placement="bottom-start"
									disableInteractive
									disableHoverListener
									open={!fullscreenExitHover && fullscreenHover}
								>
									<NavLink
										className={classes.menuNavIconLink}
										to="#"
										onClick={(e) => handleToggleFullscreen(e, !fullscreenMode)}
										onMouseEnter={() => setFullscreenHover(true)}
										onMouseLeave={() => setFullscreenHover(false)}
									>
										<FullscreenIcon className={classes.icon} />
									</NavLink>
								</Tooltip>
							)}
						</li>

						{RIGHT_SIDE_MENU_LIST.map((menu) => {
							const Icon = menu.icon
							return (
								<li className="nav-item" key={menu.name}>
									<div
										hidden={!rightSideMenu.includes(menu.name)}
										className={classes.menuNavIconLink}
										onClick={(e) => handleMenuIconClick(e, menu.name)}
									>
										<Icon className={classes.icon} />
									</div>
								</li>
							)
						})}
					</Box>
				</Stack>
			</Stack>

			{iconMenuSelected === 'question' && (
				<Menu
					id="fade-menu"
					MenuListProps={{
						'aria-labelledby': 'fade-button',
					}}
					anchorEl={anchorEl}
					open={open}
					onClose={handleClose}
					TransitionComponent={Fade}
				>
					<MenuItem
						onClick={() => {
							window.open(subMenuLinks['mnMobileUnits'], '_blank')
							handleClose()
						}}
					>
						{translations.mnDevices ? translations.mnDevices : t('common:tabs.devices')}
					</MenuItem>
					<MenuItem
						onClick={() => {
							window.open(subMenuLinks['mnForms'], '_blank')
							handleClose()
						}}
					>
						{translations.mnForms ? translations.mnForms : t('common:tabs.forms')}
					</MenuItem>
					<MenuItem
						onClick={() => {
							window.open(subMenuLinks['mnScheduler'], '_blank')
							handleClose()
						}}
					>
						{translations.mnScheduler ? translations.mnScheduler : t('common:tabs.scheduler')}
					</MenuItem>
					<MenuItem
						onClick={() => {
							window.open(subMenuLinks['mnLookups'], '_blank')
							handleClose()
						}}
					>
						{translations.mnLookups ? translations.mnLookups : t('common:tabs.lookups')}
					</MenuItem>
					<MenuItem
						onClick={() => {
							window.open(subMenuLinks['mnViews'], '_blank')
							handleClose()
						}}
					>
						{translations.mnView ? translations.mnView : t('common:tabs.views')}
					</MenuItem>
					<MenuItem
						onClick={() => {
							window.open(subMenuLinks['mnMyReports'], '_blank')
							handleClose()
						}}
					>
						{translations.mnReports ? translations.mnReports : t('common:tabs.reports')}
					</MenuItem>
				</Menu>
			)}
			{iconMenuSelected === 'video' && (
				<Menu
					id="fade-menu"
					MenuListProps={{
						'aria-labelledby': 'fade-button',
					}}
					anchorEl={anchorEl}
					open={open}
					onClose={handleClose}
					TransitionComponent={Fade}
				>
					<MenuItem
						onClick={() => {
							window.open(subMenuLinks['mnVideos'], '_blank')
							handleClose()
						}}
					>
						{translations.mnVideos ? translations.mnVideos : t('common:tabs.videos')}
					</MenuItem>
				</Menu>
			)}
			{iconMenuSelected === 'headset' && (
				<Menu
					id="fade-menu"
					MenuListProps={{
						'aria-labelledby': 'fade-button',
					}}
					anchorEl={anchorEl}
					open={open}
					onClose={handleClose}
					TransitionComponent={Fade}
				>
					<MenuItem
						onClick={() => {
							window.open(
								subMenuLinks['mnSupport'],
								'supportwindow',
								'menubar=1,resizable=1,width=1000,height=1000'
							)
							handleClose()
						}}
					>
						{t('common:tabs.openSupportTicket')}
					</MenuItem>
					<MenuItem onClick={(e) => launchChat(e)}>{t('common:tabs.chatWithSupport')}</MenuItem>
				</Menu>
			)}
			{iconMenuSelected === 'contruction' && (
				<Menu
					id="fade-menu"
					MenuListProps={{
						'aria-labelledby': 'fade-button',
					}}
					anchorEl={anchorEl}
					open={open}
					onClose={handleClose}
					TransitionComponent={Fade}
				>
					<MenuItem onClick={(e) => launchBuildForms(e)}>{t('common:tabs.buildForms')}</MenuItem>
					<MenuItem onClick={(e) => launchBuildReports(e)}>
						{t('common:tabs.buildReports')}
					</MenuItem>
				</Menu>
			)}
			{iconMenuSelected === 'setting' && (
				<Menu
					id="fade-menu"
					MenuListProps={{
						'aria-labelledby': 'fade-button',
					}}
					anchorEl={anchorEl}
					open={open}
					onClose={handleClose}
					TransitionComponent={Fade}
				>
					<MenuItem
						onClick={() => {
							window.open(subMenuLinks['mnMarketplace'], '_blank')
							handleClose()
						}}
					>
						{translations.mnMarketplace ? translations.mnMarketplace : t('common:tabs.marketplace')}
					</MenuItem>
					<MenuItem onClick={(e) => launchMobileUsers(e)}>{t('common:tabs.manageMobile')}</MenuItem>
					<MenuItem onClick={(e) => launchWebUsers(e)}>{t('common:tabs.manageWeb')}</MenuItem>
					<MenuItem onClick={(e) => launchConfigSystem(e)}>
						{translations.mnConfigureSystem
							? translations.mnConfigureSystem
							: t('common:tabs.configureSystem')}
					</MenuItem>
					<MenuItem onClick={(e) => launchMyAccount(e)}>
						{translations.mnManageAccount
							? translations.mnManageAccount
							: t('common:tabs.manageAccount')}
					</MenuItem>
				</Menu>
			)}
		</Box>
	)
}

export default DisNavTabs
