import _ from 'lodash'

const defaultQuery = {
	columns: [],
	filter: {
		conditions: [
			{ preset: 'TODAY', target: '@StartTime', type: 'EQ' },
			{ preset: 'ALL', target: '@UserStatus', type: 'EQ' },
		],
	},
}

const defaultViewDeletedState = {
	view: {},
	state: false,
}

export const VIEWS_ACTIONS = {
	ACTION: 'VIEWS_ACTION',
	FORM_COLUMNS: 'VIEWS_FORM_COLUMNS',
	FORM_FILTER_CONDITIONS: 'VIEWS_FORM_FILTER_CONDITIONS',
	FORM_DELETE_SELECTION: 'VIEWS_FORM_DELETE_SELECTION',
	DATA_GRID: 'VIEWS_DATA_GRID',
	HAS_DELETED_VIEW: 'VIEWS_HAS_DELETED_VIEW',
	HAS_NEW_VIEW: 'VIEWS_HAS_NEW_VIEW',
	VIEW_DELETED: 'VIEWS_VIEW_DELETED',
	VIEW_SELECTED: 'VIEWS_VIEW_SELECTED',
	VIEW_REFRESH: 'VIEWS_VIEW_REFRESH',
	VIEW_UNAVAILABLE: 'VIEWS_VIEW_UNAVAILABLE',
	VIEW_DATA: 'VIEWS_VIEW_DATA',
	VIEW_UPDATE: 'VIEWS_VIEW_UPDATE',
	VIEW_CREATE: 'VIEWS_VIEW_CREATE',
	RESET: 'VIEWS_RESET',
	VIEW_RESTORE_PRODUCER: 'VIEWS_VIEW_RESTORE_PRODUCER',
	TAB_CHANGE: 'VIEWS_TAB_CHANGE',
	NAME: 'Views',
	ROWS_PER_PAGE: 'VIEWS_ROWS_PER_PAGE',
	UPDATE_VIEW_SELECTED_KEY: 'VIEWS_UPDATE_VIEW_SELECTED_KEY',
	OLD_VIEW_SELECTED_KEY: 'VIEWS_OLD_VIEW_SELECTED_KEY',
	CLIENT_FILTERS: 'VIEWS_CLIENT_FILTERS',
}

const initialState = {
	action: null,
	formColumns: [],
	formFilterConditions: {},
	formDeleteSelection: [],
	hasDeletedView: false,
	hasNewView: false,
	viewDeleted: defaultViewDeletedState,
	viewSelected: {},
	viewRefresh: false,
	viewUnavailable: false,
	viewUpdate: {},
	viewCreate: {},
	viewData: {},
	dataGrid: {
		title: null,
		titleFooter: null,
		query: defaultQuery,
		queryView: {},
		columns: [],
		records: [],
		viewSession: false,
	},
	rowsPerPage: 100,
	oldViewSelectedKey: null,
	clientFilters: {},
}

const viewsReducer = (state = initialState, action) => {
	switch (action.type) {
		case VIEWS_ACTIONS.ACTION: {
			return {
				...state,
				action: action.payload,
			}
		}
		case VIEWS_ACTIONS.FORM_COLUMNS: {
			return {
				...state,
				formColumns: action.payload,
			}
		}
		case VIEWS_ACTIONS.FORM_FILTER_CONDITIONS: {
			return {
				...state,
				formFilterConditions: action.payload,
			}
		}
		case VIEWS_ACTIONS.FORM_DELETE_SELECTION: {
			return {
				...state,
				formDeleteSelection: action.payload,
			}
		}
		case VIEWS_ACTIONS.DATA_GRID: {
			return {
				...state,
				dataGrid: { ...state.dataGrid, ...action.payload },
			}
		}
		case VIEWS_ACTIONS.HAS_DELETED_VIEW: {
			return {
				...state,
				hasDeletedView: action.payload,
			}
		}
		case VIEWS_ACTIONS.HAS_NEW_VIEW: {
			return {
				...state,
				hasNewView: action.payload,
			}
		}
		case VIEWS_ACTIONS.VIEW_DELETED: {
			return {
				...state,
				viewDeleted: action.payload === false ? initialState.viewDeleted : action.payload,
			}
		}
		case VIEWS_ACTIONS.VIEW_SELECTED: {
			return {
				...state,
				viewSelected: action.payload,
				dataGrid: _.cloneDeep(initialState.dataGrid),
			}
		}
		case VIEWS_ACTIONS.UPDATE_VIEW_SELECTED_KEY: {
			return {
				...state,
				viewSelected: { ...state.viewSelected, key: action.payload },
			}
		}
		case VIEWS_ACTIONS.OLD_VIEW_SELECTED_KEY: {
			return {
				...state,
				oldViewSelectedKey: action.payload,
			}
		}
		case VIEWS_ACTIONS.VIEW_DATA: {
			return {
				...state,
				viewData: action.payload,
			}
		}
		case VIEWS_ACTIONS.VIEW_UNAVAILABLE: {
			return {
				...state,
				viewUnavailable: action.payload,
			}
		}
		case VIEWS_ACTIONS.VIEW_UPDATE: {
			return {
				...state,
				viewUpdate: action.payload,
			}
		}
		case VIEWS_ACTIONS.VIEW_CREATE: {
			return {
				...state,
				viewCreate: action.payload,
			}
		}
		case VIEWS_ACTIONS.VIEW_REFRESH: {
			return {
				...state,
				viewRefresh: action.payload,
			}
		}
		case VIEWS_ACTIONS.RESET:
			return initialState
		case VIEWS_ACTIONS.VIEW_RESTORE_PRODUCER: {
			return {
				...state,
				action: action.payload.action,
				formColumns: action.payload.formColumns || [],
				formFilterConditions: action.payload.formFilterConditions || {},
				formDeleteSelection: action.payload.formDeleteSelection || [],
				hasDeletedView: action.payload.hasDeletedView,
				hasNewView: action.payload.hasNewView,
				viewDeleted: action.payload.viewDeleted || {},
				viewSelected: action.payload.viewSelected || {},
				viewRefresh: action.payload.viewRefresh,
				viewUpdate: action.payload.viewUpdate || {},
				viewCreate: action.payload.viewCreate || {},
				viewData: action.payload.viewData || {},
				dataGrid: action.payload.dataGrid || initialState.dataGrid,
				rowsPerPage: action?.payload?.rowsPerPage || 100,
			}
		}
		case VIEWS_ACTIONS.TAB_CHANGE: {
			if (VIEWS_ACTIONS.NAME === action.payload) {
				return state
			} else {
				return {
					...state,
					viewSelected: {},
				}
			}
		}
		case VIEWS_ACTIONS.ROWS_PER_PAGE: {
			return {
				...state,
				rowsPerPage: action.payload,
			}
		}
		case VIEWS_ACTIONS.CLIENT_FILTERS: {
			return {
				...state,
				clientFilters: action.payload,
			}
		}
		default:
			return state
	}
}

export default viewsReducer
