import { Alert, CircularProgress } from '@mui/material'
import myGeotabLogo from '../../../../../static/img/logos/mygeotab.svg'

export default function MapLoginForm({ onSubmit, onChangeInput, isLoading, errorMsg }) {
	return (
		<div className="login-form">
			<div className="logo">
				<img src={myGeotabLogo} alt="doForms" />
			</div>
			<form onSubmit={onSubmit}>
				<div className="form-element">
					<label className="form-label" htmlFor="username">
						Username:
					</label>
					<input
						type="text"
						className="form-input"
						id="username"
						name="username"
						onChange={onChangeInput}
					/>
				</div>
				<div className="form-element">
					<label className="form-label" htmlFor="password">
						Password:
					</label>
					<input
						type="password"
						className="form-input"
						id="password"
						name="password"
						onChange={onChangeInput}
					/>
				</div>
				<div className="form-element">
					<label className="form-label" htmlFor="account">
						Account:
					</label>
					<input
						type="text"
						className="form-input"
						id="account"
						name="account"
						onChange={onChangeInput}
					/>
				</div>
				{errorMsg && <Alert severity="error">{errorMsg}</Alert>}
				<button id="connect-btn" className="connect-btn" type="submit" disabled={isLoading}>
					Connect to myGEOTAB {isLoading && <CircularProgress color="inherit" size={20} />}
				</button>
			</form>
		</div>
	)
}
