import {
	CALL_RECORDS_STORAGE,
	CHECK_LOAD_COLUMN_STORAGE,
	MAP_LOGGED_IN_INFO,
	SEARCH_INPUT_STORAGE,
	VIEW_TYPE_NOT_SHOW,
} from '../../constants'

export const getProjectName = (projectKey, projects) => {
	if (!projectKey || !projects) return
	if (!projects.length) return

	const found = projects.find((project) => project.key === projectKey)
	if (found) {
		return found.name
	}
	return ''
}

export const getFormName = (formKey, forms) => {
	if (!formKey || !forms) return
	if (!forms.length) return

	const foundForm = forms.find((form) => form.key === formKey)
	if (foundForm) {
		return foundForm.name
	}
	return ''
}

export const getDeviceName = (deviceKey, devices) => {
	if (!deviceKey || !devices) return
	if (!devices.length) return

	const found = devices.find((device) => device.key === deviceKey)
	if (found) {
		return found.name
	}
	return ''
}

export const checkNeedCallQueryAPI = (columnsKeys) => {
	const fullColumnsKeys = window.sessionStorage.getItem('full_columns_keys')
	const fullColumnsKeysData = fullColumnsKeys ? JSON.parse(fullColumnsKeys) : []

	if (fullColumnsKeysData.length === 0) return false
	return columnsKeys.some((key) => !fullColumnsKeysData.includes(key))
}

export function subtractSeconds(numOfSeconds, date = new Date()) {
	const dateCopy = new Date(date.getTime())

	dateCopy.setSeconds(dateCopy.getSeconds() - numOfSeconds)

	return dateCopy
}

export function isNotEqualWithPosition(objectA, objectB) {
	if (objectA.length !== objectB.length) return false
	return JSON.stringify(objectA) !== JSON.stringify(objectB)
}

function createSessionStorage(key) {
	const store = JSON.parse(sessionStorage.getItem(key)) ?? {}

	const save = () => {
		sessionStorage.setItem(key, JSON.stringify(store))
	}

	const storage = {
		get(key) {
			return store[key]
		},
		set(key, value) {
			store[key] = value
			save()
		},
		remove(key) {
			delete store[key]
			save()
		},
	}

	return storage
}
export const callRecordsStorage = createSessionStorage(CALL_RECORDS_STORAGE)
export const searchInputStorage = createSessionStorage(SEARCH_INPUT_STORAGE)

function createLocalStorage(key) {
	const store = JSON.parse(localStorage.getItem(key)) ?? {}

	const save = () => {
		localStorage.setItem(key, JSON.stringify(store))
	}

	const storage = {
		get(key) {
			return store[key]
		},
		set(key, value) {
			store[key] = value
			save()
		},
		remove(key) {
			delete store[key]
			save()
		},
	}

	return storage
}

export const checkLoadColumnStorage = createLocalStorage(CHECK_LOAD_COLUMN_STORAGE)

export const convertToKebabCase = (string) => {
	return string
		.replace(/([a-z])([A-Z])/g, '$1-$2')
		.replace(/[\s_]+/g, '-')
		.toLowerCase()
}

export const getDisplayedViews = (views) => {
	if (!Array.isArray(views) || views?.length === 0) return []
	return views?.filter((view) => !VIEW_TYPE_NOT_SHOW.includes(view.type)) ?? []
}

export function isJson(str) {
	if (!str) return false
	try {
		JSON.parse(str)
	} catch (e) {
		return false
	}
	return true
}

export function showErrorMessage(error) {
	console.error('💥 ERROR::', error)
}

export function getUserTimeZone() {
	// Lấy múi giờ hiện tại của người dùng
	return Intl.DateTimeFormat().resolvedOptions().timeZone
}

function getDateInUserTimeZone(date) {
	const userTimeZone = getUserTimeZone()
	const dateOptions = { timeZone: userTimeZone }
	return new Date(date.toLocaleString('en-US', dateOptions))
}

export function getDefaultDateRange() {
	// Lấy ngày hiện tại
	const currentDate = new Date()

	// Lấy ngày mai
	const tomorrow = new Date(currentDate)
	tomorrow.setDate(currentDate.getDate() + 1)

	// Chuyển đổi ngày và giờ sang múi giờ của người dùng
	const fromDate = getDateInUserTimeZone(currentDate)
	const toDate = getDateInUserTimeZone(tomorrow)

	// Đặt giờ cho ngày bắt đầu và kết thúc
	fromDate.setHours(0, 0, 0, 0)
	toDate.setHours(0, 0, 0, 0)

	return { fromDate, toDate }
}

export function convertKmHToMph(kmH) {
	if (typeof kmH !== 'number') return 0
	const mph = kmH / 1.60934
	return Math.round(mph)
}

export function getMapLoggedInInfoFromStorage() {
	try {
		if (localStorage.getItem(MAP_LOGGED_IN_INFO)) {
			return JSON.parse(localStorage.getItem(MAP_LOGGED_IN_INFO))
		}
		return null
	} catch {
		return null
	}
}

export function checkAdminRole(userCurrent) {
	return userCurrent?.rights?.manage?.includes('update')
}
