import { useQuery } from '@tanstack/react-query'
import menuApi from 'apis/disApi/menuApi'
import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import DisNavTabs from '../../core/Layouts/DisNavTabs'
import NavLayout from '../../core/Layouts/NavLayout'
import { sortByPosition } from '../../pages/dis/helpers'

export const MY_MENU_KEY = 'my-menus'

const DisLayout = ({ children }) => {
	const { environment } = useSelector((state) => state)
	const [isShowMenuItems, setIsShowMenuItems] = useState(false)

	const { data } = useQuery([MY_MENU_KEY, isShowMenuItems], () => {
		if (isShowMenuItems) {
			return menuApi.getAll(environment.apiToken)
		}
		return menuApi.getMy(environment.apiToken)
	})

	const isDefaultMenu = data?.data?.isDefault

	const menuData = useMemo(() => {
		if (isShowMenuItems) {
			const list = data?.data
				?.filter((x) => !x.isDefault)
				?.map((x) => x.items.map((y) => ({ ...y, menuKey: x.key })))
			if (list?.length > 0) return list.flat()
		}

		return data?.data?.items
	}, [data?.data, isShowMenuItems])

	const sortedMenuList = useMemo(() => {
		return sortByPosition(menuData)
	}, [menuData])

	return (
		<>
			<NavLayout />
			<DisNavTabs
				menuItemList={sortedMenuList}
				isDynamicMenu={!isDefaultMenu}
				isShowMenuItems={isShowMenuItems}
				setIsShowMenuItems={setIsShowMenuItems}
			/>
			{children}
		</>
	)
}

export default DisLayout
